// Hide page content initially
document.body.style.display = 'none';

var correctPassword = "1234.,"; // Set your password

function checkPassword() {
  var userPassword = prompt("Enter Password:");
  
  if (userPassword === correctPassword) {
    // Show the page content if password is correct
    document.body.style.display = 'block';
  } else {
    alert("Wrong password! Try again.");
    checkPassword(); // Recursively ask for password
  }
}

// Call function when page loads
window.addEventListener('DOMContentLoaded', function() {
  checkPassword();
});
